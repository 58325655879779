import React from 'react';

import { CommonLayout } from '~/src/components/Layout/CommonLayout';
import { Navbar } from '~/src/components/Navbar';
import { Footer } from '~/src/components/Footer';
import { FirstScreen, Uploading, Platform, SmartCDN, LastScreen } from '../components/forMain';

export const frontmatter = {
  title: 'File uploading, processing & delivery for web and mobile apps',
  description:
    'Uploadcare provides companies with simple, powerful, developer-friendly building blocks to handle file uploading, processing, and delivery. A complete out-of-the-box solution, built for engineers by engineers.',
  schema: {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://uploadcare.com/',
    name: 'Uploadcare',
    alternateName: ['Uploadcare Inc.', 'Uploadcare.com'],
    description: 'Uploadcare is file management platform and a CDN for user-generated content.',
    provider: {
      '@type': 'Corporation',
      name: 'Uploadcare',
      legalName: 'Uploadcare Inc.',
      url: 'https://uploadcare.com/company/',
      logo: 'https://ucarecdn.com/7e668407-0c26-498c-9ea0-8709a5343569/uploadcare-logo.png',
    },
    hasPart: {
      '@type': 'CreativeWork',
      name: 'Uploadcare Support',
      url: 'help.uploadcare.com',
    },
  },
};

const Index = ({ location }) => (
  <CommonLayout meta={frontmatter} pathName={location.pathname} isDark>
    <Navbar isLight />

    <FirstScreen
      btnSignUpProps={{
        'data-analytics': 'btn_signup_0',
      }}
    />
    <Uploading />
    <Platform />
    <SmartCDN />
    <LastScreen />

    <Footer />
  </CommonLayout>
);

export default Index;
