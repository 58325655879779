import React from 'react';
import styled from 'styled-components';
import { ThemesEnum } from '@uc-common/theme';

import { FirstScreen, Uploading, Platform, SmartCDN, LastScreen } from '../../components/forMain';
import { frontmatter as originalFrontmatter } from '../index';
import { Navbar, NavbarModesEnum } from '../../components/Navbar';
import { CommonLayout } from '../../components/Layout/CommonLayout';

export const frontmatter = {
  noindex: true,
  canonicalUrl: 'https://uploadcare.com/',
};

const StyledCommonLayout = styled(CommonLayout)`
  background: #000;
`;

const Uploadcare = ({ location }) => (
  <StyledCommonLayout
    meta={{
      ...originalFrontmatter,
      ...frontmatter,
    }}
    pathName={location.pathname}
    isDark
  >
    <Navbar mode={NavbarModesEnum.PPC} initialTheme={ThemesEnum.DARK} />
    <FirstScreen />
    <Uploading />
    <Platform />
    <SmartCDN />
    <LastScreen />
  </StyledCommonLayout>
);

export default Uploadcare;
